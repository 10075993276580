import { defineMessages } from "react-intl";

export const messages = defineMessages({
  dateTitle: {
    id: "bookingslist.item.date.title",
    defaultMessage: "Date",
    description: "title for date in list item",
  },
  contactTitle: {
    id: "bookingslist.item.contact.title",
    defaultMessage: "Contact",
    description: "title for contact in list item",
  },
  participantsTitle: {
    id: "bookingslist.item.participants.title",
    defaultMessage: "Participants",
    description: "title for participants in list item",
  },
  statusConfirmed: {
    id: "bookingslist.item.status.confirmed",
    defaultMessage: "Confirmed",
    description: "description text of Status for confirmed participants",
  },
  statusOf: {
    id: "bookingslist.item.status.of",
    defaultMessage: "of",
    description: "Example: 2 <statusOf> 4",
  },
  cancelMeetingButton: {
    id: "bookingslist.item.button.cancel",
    defaultMessage: "Cancel",
    description: "cancel button label",
  },
  meetingNotStartedYet: {
    id: "bookingslist.item.button.meetingNotStarted",
    defaultMessage: "Visit has not been started",
    description: "Inform user that the meeting didn't start yet",
  },
});
