import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { Button, Grid, GridSize, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./BookingsListItem.style";
import BookingsListItemText from "../BookingsListItemText/BookingsListItemText";
import {
  MeetingResponse,
  MeetingStatusType,
} from "../../../../generated/meeting-api";
import {
  getFormattedDate,
  getFormattedTimespan,
  getNumberOfConfirmedParticipants,
  getParticipantsAfterRemoval,
} from "../../../../Utils/Meeting";
import { messages } from "./BookingsListItem.messages";
import AvatarGroup from "../../../AvatarGroup/AvatarGroup";
import { Variant } from "../Variant/Variant";
import UpdateMeetingButton from "../../../Buttons/UpdateMeetingButton/UpdateMeetingButton";
import CancelMeetingButton from "../../../Buttons/CancelMeeting/CancelMeetingButton";
import AcceptMeetingButton from "../../../Buttons/AcceptMeetingButton/AcceptMeetingButton";
import ConnectToMeetingButton from "../../../Buttons/ConnectToMeetingButton/ConnectToMeetingButton";
import BookingHistoryButton from "../../../Buttons/BookingHistoryButton/BookingHistoryButton";
import MeetingStatusChip from "../../../meetingStatusChip/meetingStatusChip";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { UserRoleType } from "../../../../generated/user-api";
import MeetingSourceChip from "../../../MeetingSourceChip/MeetingSourceChip";
import UpdateWebdocMeetingButton from "../../../Buttons/UpdateWebdocMeetingButton/UpdateWebdocMeetingButton";

interface GridConfig {
  md: GridSize;
  sm: GridSize;
  xs: GridSize;
}

interface GridConfigList {
  date: GridConfig;
  chips: GridConfig;
  buttons: GridConfig;
  participants: GridConfig;
  meetingTitle: GridConfig;
  statusConfirmed: GridConfig;
}

interface Props {
  variant: Variant;
  meeting: MeetingResponse;
  reloadBookings?: () => void;
  isAdmin?: boolean;
  noDivider?: boolean;
}

const BookingsListItem = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { variant, meeting, reloadBookings, noDivider } = props;
  const gridConfig: GridConfigList = {
    buttons: {
      md: variant === Variant.ADMIN_UPCOMING_BOOKINGS ? 4 : 6,
      sm: variant === Variant.ADMIN_UPCOMING_BOOKINGS ? 4 : 6,
      xs: 12,
    },
    date: { md: 2, sm: 4, xs: 6 },
    chips: { md: 12, sm: 12, xs: 12 },
    participants: { md: 4, sm: 4, xs: 6 },
    meetingTitle: { md: 12, sm: 12, xs: 12 },
    statusConfirmed: { md: 2, sm: 4, xs: 6 },
  };
  const [authenticatedUser] = useAuthenticatedUser();
  const participants = getParticipantsAfterRemoval(meeting.participants || [], [
    authenticatedUser.user?.id || "",
  ]);

  return (
    <>
      <ListItem
        alignItems="flex-start"
        className={classes.root}
        divider={!noDivider}
      >
        <Grid container>
          <Grid
            item
            {...gridConfig.chips}
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <MeetingStatusChip status={meeting.status} />
            <MeetingSourceChip source={meeting.source} />
          </Grid>
          {meeting.title && (
            <Grid item {...gridConfig.meetingTitle}>
              <Typography color="textPrimary" variant="h6">
                {meeting.title}
              </Typography>
            </Grid>
          )}
          <Grid item {...gridConfig.date}>
            <BookingsListItemText
              headerText={intl.formatMessage(messages.dateTitle)}
              primaryText={getFormattedDate(meeting.startDateTime)}
              secondaryText={getFormattedTimespan(
                meeting.startDateTime,
                meeting.duration
              )}
            />
          </Grid>
          {variant === Variant.ADMIN_UPCOMING_BOOKINGS && (
            <Grid item {...gridConfig.statusConfirmed}>
              <BookingsListItemText
                headerText={intl.formatMessage(messages.statusConfirmed)}
                primaryText={`${getNumberOfConfirmedParticipants(
                  meeting.participants
                )} ${intl.formatMessage(messages.statusOf)} ${
                  meeting.participants?.length
                }`}
              />
            </Grid>
          )}
          <Grid item {...gridConfig.participants}>
            <BookingsListItemText
              headerText={intl.formatMessage(messages.participantsTitle)}
            />
            <AvatarGroup max={4} participants={participants} />
          </Grid>
          <Grid item {...gridConfig.buttons} className={classes.buttonGrid}>
            <UpdateMeetingButton
              contextVariant={variant}
              onChanged={reloadBookings}
              className={classes.button}
              meeting={meeting}
            />
            <UpdateWebdocMeetingButton
              className={classes.button}
              meeting={meeting}
            />
            <CancelMeetingButton
              contextVariant={variant}
              onCanceledMeeting={reloadBookings}
              className={classes.button}
              label={intl.formatMessage(messages.cancelMeetingButton)}
              meeting={meeting}
            />
            <AcceptMeetingButton
              onAccepted={reloadBookings}
              className={classes.button}
              contextVariant={variant}
              meeting={meeting}
            />
            {meeting.status === MeetingStatusType.Created &&
              variant === Variant.UPCOMING_BOOKINGS &&
              authenticatedUser.user?.userRole !== UserRoleType.Staff && (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.button}
                >
                  {intl.formatMessage(messages.meetingNotStartedYet)}
                </Button>
              )}
            <ConnectToMeetingButton
              className={classes.button}
              contextVariant={variant}
              meeting={meeting}
            />
            <BookingHistoryButton
              className={classes.button}
              contextVariant={variant}
              meeting={meeting}
            />
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default BookingsListItem;
