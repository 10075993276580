import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Container } from "@material-ui/core";
import { QueryParamProvider } from "use-query-params";
import { useStyles } from "./Routes.style";
import { ROUTES } from "./Routes.config";
import PrivateRoute from "./Components/PrivateRoute";
import PublicRoute from "./Components/PublicRoute";
import { RoutePath } from "./RoutePath";
import { useCurrentPath } from "../../Providers/CurrentPathProvider/CurrentPathProvider";
import { useCustomerIsAdminPortal } from "../../Providers/CustomerProvider/CustomerProvider";

interface Props {
  appBar: JSX.Element;
  footer: JSX.Element;
}

const Routes = (props: Props) => {
  const classes = useStyles();
  const [path] = useCurrentPath();
  const { appBar, footer } = props;
  const isAdminPortal = useCustomerIsAdminPortal();
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <div className={classes.root}>
          {appBar}

          <Container
            maxWidth={path === RoutePath.SESSION_VIDEO ? false : "lg"}
            className={
              path === RoutePath.SESSION_VIDEO ? classes.mainFull : classes.main
            }
          >
            <Switch>
              {!isAdminPortal && (
                <Redirect exact from="/" to={ROUTES.dashboard.path || ""} />
              )}
              <Redirect exact from="/" to={ROUTES.portalCustomers.path || ""} />
              {/* Private Routes */}
              <PrivateRoute {...ROUTES.sign} />
              <PrivateRoute {...ROUTES.files} />
              <PrivateRoute {...ROUTES.clients} />
              <PrivateRoute {...ROUTES.account} />
              <PrivateRoute {...ROUTES.bookings} />
              <PrivateRoute {...ROUTES.messages} />
              <PrivateRoute {...ROUTES.dashboard} />
              <PrivateRoute {...ROUTES.testVideo} />
              <PrivateRoute {...ROUTES.sessionVideo} />
              <PrivateRoute {...ROUTES.adminBookings} />
              <PrivateRoute {...ROUTES.adminPatients} />
              <PrivateRoute {...ROUTES.adminSettings} />
              <PrivateRoute {...ROUTES.portalCustomers} />
              <PrivateRoute {...ROUTES.bookingHistory} />
              <PrivateRoute {...ROUTES.adminExternals} />
              <PrivateRoute {...ROUTES.adminCaregivers} />
              <PrivateRoute {...ROUTES.meetingTemplates} />
              <PrivateRoute {...ROUTES.accountCompletion} />
              <PrivateRoute {...ROUTES.customerEmailSettings} />
              <PrivateRoute {...ROUTES.customerThemeSettings} />
              <PrivateRoute {...ROUTES.portalCustomerSettings} />
              <PrivateRoute {...ROUTES.customerContentSettings} />
              <PrivateRoute {...ROUTES.customerFeaturesSettings} />
              <PrivateRoute {...ROUTES.customerAnonymizeSettings} />
              <PrivateRoute {...ROUTES.customerAgreementSettings} />

              {/* Public Routes */}
              <PublicRoute {...ROUTES.login} />
              <PublicRoute {...ROUTES.sync} />
              <PublicRoute {...ROUTES.inviteVerify} />
              <PublicRoute {...ROUTES.authentication} />
              <PublicRoute {...ROUTES.verification} />
              <PublicRoute {...ROUTES.errorPage} />
            </Switch>
          </Container>
          {footer}
        </div>
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;
