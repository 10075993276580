import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import TextField from "../../../../Fields/TextField/TextField";
import { useCustomerId } from "../../../../../Providers/CustomerProvider/CustomerProvider";
import { messages } from "./SearchField.messages";
import { useAuthenticatedUser } from "../../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

export enum Variant {
  INVITE,
  CREATE,
  INVITE_OR_CREATE,
}

interface InitialValues {
  searchQuery: string;
}

export interface Query {
  email: string;
  personNumber: string;
}

interface Props {
  onSearch: (query?: Query) => void;
  variant: Variant;
}

const validEmail = new RegExp("\\S+@\\S+\\.\\S+");
const validPersonNumber = new RegExp("^\\d{10,12}$");

const SearchField = (props: Props) => {
  const intl = useIntl();
  const { onSearch, variant } = props;
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();

  const initialValues: InitialValues = {
    searchQuery: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  });

  const handleSearch = (currentQuery: string) => {
    const isValidEmail = validEmail.test(currentQuery);
    const isValidPersonNumber = validPersonNumber.test(
      currentQuery.replace("-", "")
    );

    if (customerId && authenticatedUser.user) {
      const query = {
        personNumber: isValidPersonNumber
          ? currentQuery.replace("-", "")
          : undefined,
        email: isValidEmail ? currentQuery : undefined,
      } as Query;

      onSearch(query);
    }
  };

  const isSearchable = (query?: string): boolean => {
    if (query) {
      switch (variant) {
        case Variant.CREATE:
          return validPersonNumber.test(query.replace("-", ""));
        case Variant.INVITE:
          return validEmail.test(query);
        case Variant.INVITE_OR_CREATE:
          return (
            validEmail.test(query) ||
            validPersonNumber.test(query.replace("-", ""))
          );
        default:
          return false;
      }
    }
    return false;
  };

  const getPlaceholder = (): string => {
    switch (variant) {
      case Variant.CREATE:
        return "YYYYMMDDNNNN";
      case Variant.INVITE:
        return "address@example.se";
      case Variant.INVITE_OR_CREATE:
        return "YYYYMMDDNNNN or address@example.se";
      default:
        return "";
    }
  };

  const getLabel = (): string => {
    switch (variant) {
      case Variant.CREATE:
        return intl.formatMessage(messages.searchCreateLabel);
      case Variant.INVITE:
        return intl.formatMessage(messages.searchInviteLabel);
      case Variant.INVITE_OR_CREATE:
        return intl.formatMessage(messages.searchCreateOrInviteLabel);
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isSearchable(formik.values.searchQuery)) {
      handleSearch(formik.values.searchQuery);
    } else {
      onSearch(undefined);
    }
  }, [formik.values.searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TextField
        id="searchQuery"
        formik={formik}
        placeholder={getPlaceholder()}
        label={getLabel()}
      />
    </>
  );
};

export default SearchField;
