import React from "react";
import { Chip, TextField as MuiTextField, useTheme } from "@material-ui/core";
import { FormikProps } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  displayUser,
  formatName,
  formatPersonNumber,
} from "../../../Utils/User";
import { User } from "../../../Models/User";

export interface NotAvailableUser {
  id: string;
  name: string;
}

interface Props {
  id: string;
  label: string;
  errorMsg?: string;
  formik: FormikProps<any>;
  disabledFields?: string[];
  size?: "small" | "medium";
  useCheckAvailability?: boolean;
  meetingLeader?: User;
  userOptionList?: User[];
  userFixedList?: User[];
}

const UserSelectField = (props: Props) => {
  const {
    id,
    size,
    label,
    formik,
    errorMsg,
    disabledFields,
    meetingLeader,
    userFixedList,
    userOptionList,
  } = props;
  const { errors, touched } = formik;
  const theme = useTheme();

  const addIfNotExist = (user: User, users: User[]): User[] => {
    if (!users.find((u) => u.id === user.id)) {
      users.push(user);
    }

    return users;
  };

  const handleChange = async (value: User[]) => {
    let newValue = value;
    userFixedList?.forEach((user) => {
      newValue = addIfNotExist(user, newValue);
    });

    // If user attempts to clear staff field, always add meetingleader (this is only used when creating meeting)
    if (
      meetingLeader &&
      id === "staffParticipants" &&
      (!value || value?.length === 0)
    ) {
      newValue = addIfNotExist(meetingLeader, newValue);
    }
    await formik.setFieldValue(id, newValue);
    formik.setFieldTouched(id, true);
  };

  const getDisabledUserChips = () => {
    const mergedUsers = meetingLeader
      ? [meetingLeader, ...(userFixedList || [])]
      : [...(userFixedList || [])];

    return mergedUsers.map((user) => user?.id);
  };

  const formatLabel = (user: User): string => {
    const nameLabel =
      formatName(user.firstName, user.lastName) === "" && user.email
        ? user.email
        : formatName(user.firstName, user.lastName);

    const personNumberLabel = `${nameLabel} (${formatPersonNumber(
      user.personNumber
    )})`;

    return user.personNumber ? personNumberLabel : nameLabel;
  };

  return (
    <>
      <Autocomplete
        id={id}
        disabled={disabledFields?.includes(id)}
        multiple
        filterSelectedOptions
        value={formik.values[id]}
        options={userOptionList || []}
        onChange={(event, value) => {
          handleChange(value);
        }}
        getOptionLabel={(option: User) => formatLabel(option)}
        renderOption={(option) => displayUser(option)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            return (
              <Chip
                size={size}
                label={displayUser(option)}
                {...getTagProps({ index })}
                disabled={getDisabledUserChips().includes(option.id)}
              />
            );
          })
        }
        renderInput={(params) => (
          <MuiTextField
            {...params}
            fullWidth
            label={label}
            InputLabelProps={{
              style: { color: theme.palette.text.secondary },
            }}
            variant="outlined"
            error={!!(errors[id] && touched[id])}
            helperText={touched[id] && errors[id] ? errorMsg : null}
            disabled={disabledFields?.includes(id)}
          />
        )}
      />
    </>
  );
};

export default UserSelectField;
