import React from "react";
import { FormikProps } from "formik";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { Alert } from "@material-ui/lab";
import ReactQuill from "react-quill";
import { messages } from "./CreateMessagesFields.messages";
import { UserRoleType } from "../../../../generated/user-api";
import PermissionController from "../../../PermissionController/PermissionController";
import TextField from "../../TextField/TextField";
import { messages as errorMessages } from "../../MeetingFields/Components/Validation/Validation.messages";
import UserSelectField from "../../UserSelectField/UserSelectField";
import { User } from "../../../../Models/User";
import FeatureController from "../../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../../generated/customersettings-api";

export interface MessageFormikValues {
  subject: string;
  contents: string;
  staffRecipients: User[];
  clientRecipients: User[];
  externalRecipients: User[];
}

interface Props {
  formik: FormikProps<MessageFormikValues>;
  disabledFields?: string[];
  staffRecipientOptionList?: User[];
  clientRecipientOptionList?: User[];
  externalRecipientOptionList?: User[];
}

const getErrorMessage = (intl: IntlShape, error?: string) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const CreateMessageFields = (props: Props) => {
  const intl = useIntl();
  const {
    formik,
    disabledFields,
    clientRecipientOptionList,
    staffRecipientOptionList,
    externalRecipientOptionList,
  } = props;
  const { errors } = formik;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = async (field: string, value: any) => {
    await formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  };

  return (
    <Grid container spacing={2}>
      {[...formik.values.staffRecipients, ...formik.values.clientRecipients]
        .length > 1 && (
        <Grid item xs={12}>
          <Alert severity="warning">
            {intl.formatMessage(messages.warningMessage)}
          </Alert>
        </Grid>
      )}
      {[...formik.values.externalRecipients, ...formik.values.clientRecipients]
        .length > 0 && (
        <FeatureController requiredFeature={CustomerFeatureType.OneWayMessage}>
          <Grid item xs={12}>
            <Alert severity="info">
              {intl.formatMessage(messages.warningOneWayMessage)}
            </Alert>
          </Grid>
        </FeatureController>
      )}
      <Grid item xs={12}>
        <UserSelectField
          id="staffRecipients"
          size="small"
          formik={formik}
          disabledFields={disabledFields}
          userOptionList={staffRecipientOptionList}
          label={intl.formatMessage(messages.staffRecipientsLabel)}
          errorMsg={getErrorMessage(intl, errors.staffRecipients?.toString())}
        />
      </Grid>
      <PermissionController
        allowedRoles={[UserRoleType.Staff, UserRoleType.PlatformAdmin]}
      >
        <Grid item xs={12}>
          <UserSelectField
            id="clientRecipients"
            size="small"
            formik={formik}
            disabledFields={disabledFields}
            userOptionList={clientRecipientOptionList}
            label={intl.formatMessage(messages.clientRecipientsLabel)}
            errorMsg={getErrorMessage(
              intl,
              errors.clientRecipients?.toString()
            )}
          />
        </Grid>
        <FeatureController requiredFeature={CustomerFeatureType.ExternalUsers}>
          <Grid item xs={12}>
            <UserSelectField
              id="externalRecipients"
              size="small"
              formik={formik}
              disabledFields={disabledFields}
              userOptionList={externalRecipientOptionList}
              label={intl.formatMessage(messages.externalRecipientsLabel)}
              errorMsg={getErrorMessage(
                intl,
                errors.externalRecipients?.toString()
              )}
            />
          </Grid>
        </FeatureController>
      </PermissionController>
      <Grid item xs={12}>
        <TextField
          id="subject"
          formik={formik}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.subjectLabel)}
          errorMsg={getErrorMessage(intl, errors.subject?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <ReactQuill
          theme="snow"
          id="contents"
          value={formik.values.contents}
          onChange={(value) => handleChange("contents", value)}
          readOnly={disabledFields?.includes("contents")}
        />
      </Grid>
    </Grid>
  );
};

export default CreateMessageFields;
