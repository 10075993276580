import React from "react";
import { Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  MeetingResponse,
  PaginatedMeetingResponseList,
} from "../../../../generated/meeting-api";
import { useStyles } from "./OngoingMeetingCard.style";
import { messages } from "./OngoingMeetingCard.messages";
import {
  getFormattedDate,
  getFormattedTimespan,
  getParticipantsAfterRemoval,
} from "../../../../Utils/Meeting";
import { displayUser } from "../../../../Utils/User";
import { Variant } from "../../../../Components/BookingsList/Components/Variant/Variant";
import ConnectToMeetingButton from "../../../../Components/Buttons/ConnectToMeetingButton/ConnectToMeetingButton";
import AvatarGroup from "../../../../Components/AvatarGroup/AvatarGroup";

interface Props {
  bookings?: PaginatedMeetingResponseList;
}
const OngoingMeetingCard = (props: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const [authenticatedUser] = useAuthenticatedUser();
  const { bookings } = props;
  const filteredParticipants = (meeting: MeetingResponse) =>
    getParticipantsAfterRemoval(meeting?.participants || [], [
      authenticatedUser.user?.id || "",
    ]);

  if (!bookings?.data || bookings?.data.length === 0) {
    return <></>;
  }

  const CustomAvatar = (meeting: MeetingResponse) => {
    const participants = getParticipantsAfterRemoval(
      meeting.participants || [],
      [authenticatedUser.user?.id || ""]
    );

    if (participants.length > 1) {
      return (
        <>
          <Typography variant="h4" className={classes.informationText}>
            {meeting.title}
          </Typography>
          <AvatarGroup participants={participants} max={10} center />
        </>
      );
    }

    return (
      <>
        <Typography variant="h4" className={classes.informationText}>
          {displayUser(filteredParticipants(meeting)[0])}
        </Typography>
        <Typography className={classes.informationText}>
          {meeting.title}
        </Typography>
      </>
    );
  };

  return (
    <>
      {bookings.data.map((meeting) => (
        <Paper key={meeting.id} className={classes.root}>
          <Typography className={classes.heading}>
            {intl.formatMessage(messages.title).toUpperCase()}
          </Typography>
          <CustomAvatar {...meeting} />
          <Typography className={classes.informationText}>
            {getFormattedDate(meeting.startDateTime)}{" "}
            {getFormattedTimespan(meeting.startDateTime, meeting.duration)}
          </Typography>
          <ConnectToMeetingButton
            meeting={meeting}
            className={classes.button}
            contextVariant={Variant.DASHBOARD_ONGOING_BOOKINGS}
            overrideLabel={messages.buttonLabel}
          />
        </Paper>
      ))}
    </>
  );
};

export default OngoingMeetingCard;
