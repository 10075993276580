import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Chip, CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useDebounce } from "@react-hook/debounce";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useCustomerId } from "../../../../Providers/CustomerProvider/CustomerProvider";
import { useStyles } from "../../PrivateNotesCard.style";
import { messages } from "../../PrivateNotesCard.messages";
import { notesService } from "../../../../Providers/ServiceProvider/ServiceProvider";

interface Props {
  initialValue: string;
  notesId: string;
  meetingId: string;
  fullHeight?: boolean;
}

const PrivateNotesEditor = (props: Props) => {
  const { meetingId, fullHeight, notesId, initialValue } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [notes, setNotes] = useState(initialValue);
  const [notesDebounce, setNotesDebounce] = useDebounce(initialValue, 1000);
  const [savedOn, setSavedOn] = useState<Date>();
  const [isTyping, setIsTyping] = useState(false);

  const ChipIcon = (loading: boolean) => {
    return loading ? (
      <CircularProgress size={12} className={classes.chipNotSaved} />
    ) : (
      <CheckCircleOutlineIcon color="action" />
    );
  };

  const updateNote = () => {
    if (customerId) {
      notesService()
        .updateMeetingNotes({
          customerId,
          meetingId,
          notesId,
          notesRequest: { notes },
        })
        .then((res) => setSavedOn(res.updatedOn))
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        );
    }
  };

  useEffect(() => {
    setIsTyping(true);
    setNotesDebounce(notes);
  }, [notes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateNote();
    setIsTyping(false);
  }, [notesDebounce]); // eslint-disable-line react-hooks/exhaustive-deps

  const quilsList: HTMLCollection = document.getElementsByClassName(
    "ql-editor"
  );

  let found = false;
  Array.from(quilsList).forEach((element) => {
    const noteElement = element;
    noteElement.className = "ql-editor";
    if (element.innerHTML === notes && !found) {
      noteElement.className = `${
        fullHeight ? classes.reqFullHeight : classes.req
      } ql-editor `;
      found = true;
    }
  });
  return (
    <>
      <ReactQuill
        theme="snow"
        value={notes}
        onChange={setNotes}
        readOnly={notesId === undefined}
      />
      <Box component="div" className={classes.buttonEnd}>
        {savedOn && (
          <div className={classes.savedOn}>
            <Chip
              className={isTyping ? classes.chipNotSaved : undefined}
              icon={ChipIcon(isTyping)}
              size="small"
              label={
                isTyping
                  ? intl.formatMessage(messages.autoSaveStatusNotSavedLabel)
                  : intl.formatMessage(messages.autoSaveStatusSavedLabel)
              }
            />
          </div>
        )}
      </Box>
    </>
  );
};

export default PrivateNotesEditor;
