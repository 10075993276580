import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormikProps, useFormik } from "formik";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import SendIcon from "@material-ui/icons/Send";
import { messages } from "./CreateMessageDialog.messages";
import DialogCloseButton from "../../Buttons/DialogCloseButton/DialogCloseButton";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import CreateMessageFields, {
  MessageFormikValues,
} from "../../Fields/MessageFields/CreateMessageFields/CreateMessageFields";
import {
  PaginatedContactResponseList,
  SortByType,
  SortOrderType,
} from "../../../generated/contact-api";
import { UserAuthorityType } from "../../../generated/authentication-api";
import { contactToUser } from "../../../Utils/ModelConverter";
import { UserRoleType, UserStatusType } from "../../../generated/user-api";
import { getUsersIds } from "../../../Utils/User";
import ButtonLoadingWrapper from "../../Buttons/ButtonLoadingWrapper/ButtonLoadingWrapper";
import {
  contactService,
  messageService,
  userService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import { User } from "../../../Models/User";
import { hasAnyOfRoles } from "../../PermissionController/PermissionController";

interface Props {
  onCreated?: () => void;
  isOpen: boolean;
  onClose?: () => void;
  prePopulatedRecipients: User[];
}

const CreateMessageDialog = (props: Props) => {
  const { isOpen, onClose, onCreated, prePopulatedRecipients } = props;
  const intl = useIntl();
  const theme = useTheme();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const [authenticatedUser] = useAuthenticatedUser();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [staffList, setStaffList] = useState<User[]>();
  const [staffContactList, setStaffContactList] = useState<User[]>();
  const [clientContactList, setClientContactList] = useState<User[]>();
  const [externalContactList, setExternalContactList] = useState<User[]>();
  const [
    isCreatingMessageLoading,
    setIsCreatingMessageLoading,
  ] = useState<boolean>(false);

  const filterContacts = (
    contactResponseList: PaginatedContactResponseList,
    filter: UserRoleType
  ): User[] => {
    return (
      contactResponseList.data
        ?.filter((contact) => contact.userRole === filter)
        .map(contactToUser) || []
    );
  };

  const loadContactList = () => {
    if (customerId && authenticatedUser.user?.id) {
      contactService()
        .listContactsForUserDecorated(authenticatedUser.user, {
          customerId,
          userStatus: UserStatusType.Active,
          userId: authenticatedUser.user.id,
          sortOrder: SortOrderType.Ascending,
          sortBy: SortByType.Popularity,
          pageSize: 1000, // maximum contacts loaded
        })
        .then((res) => {
          setStaffContactList(filterContacts(res, UserRoleType.Staff));
          setClientContactList(filterContacts(res, UserRoleType.Client));
          setExternalContactList(filterContacts(res, UserRoleType.External));
        });
    }
  };

  const loadStaff = (): void => {
    if (customerId) {
      userService()
        .listUsersByCustomerAndSearchParams({
          pageSize: 1000,
          page: 1,
          customerId,
          userStatus: UserStatusType.Active,
          userRole: UserRoleType.Staff,
          userAuthorities: [UserAuthorityType.AttendMeeting],
        })
        .then((res) => {
          const filteredList = res.data?.filter(
            (user: User) => user.id !== authenticatedUser.user?.id
          );
          setStaffList(filteredList);
        });
    }
  };

  const createMessage = (
    formik: FormikProps<MessageFormikValues>,
    subject: string,
    contents: string,
    recipients: string[]
  ) => {
    if (customerId && authenticatedUser.user) {
      setIsCreatingMessageLoading(true);
      messageService()
        .createMessage({
          customerId,
          createMessageRequest: {
            subject,
            contents,
            recipients,
          },
        })
        .then(() =>
          enqueueSnackbar(intl.formatMessage(messages.success), {
            variant: "success",
          })
        )
        .then(() => formik.resetForm())
        .catch(() =>
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          })
        )
        .finally(() => {
          setIsCreatingMessageLoading(false);
          if (onCreated) {
            onCreated();
          }
          if (onClose) {
            onClose();
          }
        });
    }
  };

  const initialValues = {
    subject: "",
    contents: "",
    staffRecipients: prePopulatedRecipients.filter(
      (u) => u.userRole === UserRoleType.Staff
    ),
    clientRecipients: prePopulatedRecipients.filter(
      (u) => u.userRole === UserRoleType.Client
    ),
    externalRecipients: prePopulatedRecipients.filter(
      (u) => u.userRole === UserRoleType.External
    ),
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: TodoValidationSchema, // TODO update
    onSubmit: (values) => {
      createMessage(
        formik,
        values.subject,
        values.contents,
        getUsersIds([
          ...values.staffRecipients,
          ...values.clientRecipients,
          ...values.externalRecipients,
        ])
      );
    },
  });

  const handleClose = () => {
    formik.resetForm();
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    loadContactList();
    if (
      hasAnyOfRoles(authenticatedUser.user?.userRole, [
        UserRoleType.External,
        UserRoleType.Staff,
      ])
    ) {
      loadStaff();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      aria-labelledby="form-dialog-title"
    >
      <DialogCloseButton onClose={onClose} />
      <DialogTitle id="form-dialog-title">
        {intl.formatMessage(messages.title)}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <CreateMessageFields
            formik={formik}
            clientRecipientOptionList={clientContactList}
            externalRecipientOptionList={externalContactList}
            staffRecipientOptionList={staffList || staffContactList}
          />
        </DialogContent>
        <DialogActions>
          <ButtonLoadingWrapper isLoading={isCreatingMessageLoading}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<SendIcon />}
              disabled={isCreatingMessageLoading}
            >
              {intl.formatMessage(messages.submitButtonLabel)}
            </Button>
          </ButtonLoadingWrapper>
          <Button variant="outlined" onClick={handleClose} color="primary">
            {intl.formatMessage(messages.cancelButtonLabel)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateMessageDialog;
