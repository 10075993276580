import * as Yup from "yup";
import subMinutes from "date-fns/subMinutes";

// text string is used as a key to fetch intl message
const MeetingValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().max(50, "titleTooLong").required("titleRequired"),
    startDateTime: Yup.date().min(
      subMinutes(new Date(), 15),
      "startTimeTooLow"
    ),
    duration: Yup.number()
      .min(1, "durationTooShort")
      .required("durationRequired"),
    staffParticipants: Yup.array().min(1, "staffParticipantsRequired"),
    participants: Yup.array().when(
      ["staffParticipants", "externalParticipants"],
      {
        is: (staffArray: [], externalArray: []) =>
          staffArray.length > 1 || externalArray.length > 0,
        then: Yup.array().notRequired(),
        otherwise: Yup.array().min(1, "participantsRequired"),
      }
    ),
  });
};

export default MeetingValidationSchema;
