import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "dashboardview.ongoingmeetingcard.title",
    defaultMessage: "Ongoing meeting",
    description: "heading for ongoing meeting card",
  },
  buttonLabel: {
    id: "dashboardview.ongoingmeetingcard.button.label",
    defaultMessage: "Go to meeting session",
    description: "Action button for ongoing meeting card",
  },
});
