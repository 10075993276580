export enum RoutePath {
  LOGIN = "/login",
  SYNC = "/sync",
  INVITE_VERIFY = "/invite/verify",
  AUTHENTICATION = "/authentication",
  VERIFICATION = "/verification",
  SIGN = "/sign",
  ERROR_PAGE = "/error",
  DASHBOARD = "/dashboard",
  CLIENTS = "/clients",
  BOOKINGS = "/bookings",
  BOOKING_HISTORY = "/bookings/history",
  MESSAGES = "/messages",
  FILES = "/files",
  ACCOUNT = "/account",
  ACCOUNT_COMPLETION = "/account/completion",
  TEST_VIDEO = "/support/testvideo",
  ADMIN_PATIENTS = "/admin/patients",
  ADMIN_EXTERNALS = "/admin/externals",
  ADMIN_BOOKINGS = "/admin/bookings",
  ADMIN_CAREGIVERS = "/admin/caregivers",
  ADMIN_SETTINGS = "/admin/settings",
  PORTAL_CUSTOMERS = "/portal/customers",
  PORTAL_CUSTOMER_SETTINGS = "/portal", // Will be /portal/{customerId}
  SESSION_VIDEO = "/session/video",
  MEETING_TEMPLATES = "/meeting/templates",
  CUSTOMER_EMAIL_SETTINGS = "/settings/email",
  CUSTOMERS_THEME_SETTINGS = "/settings/theme",
  CUSTOMER_FEATURE_SETTINGS = "/settings/features",
  CUSTOMERS_ANONYMIZE_SETTINGS = "/settings/anonymize",
  CUSTOMERS_CONTENT_SETTINGS = "/settings/content",
  CUSTOMERS_AGREEMENT_SETTINGS = "/settings/agreement",
}
