import React from "react";
import { Button, Grid, GridSize, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import MeetingStatusChip from "../../../meetingStatusChip/meetingStatusChip";
import { useStyles } from "./CalendarEventInListMode.style";
import { messages } from "./CalendarEventInListMode.messages";
import { MeetingStatusType } from "../../../../generated/meeting-api";
import AvatarGroup from "../../../AvatarGroup/AvatarGroup";
import UpdateMeetingButton from "../../../Buttons/UpdateMeetingButton/UpdateMeetingButton";
import CancelMeetingButton from "../../../Buttons/CancelMeeting/CancelMeetingButton";
import AcceptMeetingButton from "../../../Buttons/AcceptMeetingButton/AcceptMeetingButton";
import ConnectToMeetingButton from "../../../Buttons/ConnectToMeetingButton/ConnectToMeetingButton";
import BookingHistoryButton from "../../../Buttons/BookingHistoryButton/BookingHistoryButton";
import BookingsListItemText from "../../../BookingsList/Components/BookingsListItemText/BookingsListItemText";
import {
  getFormattedDate,
  getFormattedTimespan,
  getNumberOfConfirmedParticipants,
} from "../../../../Utils/Meeting";
import { Variant } from "../../../BookingsList/Components/Variant/Variant";
import { useAuthenticatedUser } from "../../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { UserRoleType } from "../../../../generated/user-api";
import { Meeting } from "../../../../Models/Meeting";

interface Props {
  eventDetails: Meeting;
  eventOnClick: () => void;
  eventInfo: any;
  reloadBookings: () => void;
  calendarApi: any;
}
interface GridConfig {
  md: GridSize;
  sm: GridSize;
  xs: GridSize;
}

interface GridConfigList {
  date: GridConfig;
  buttons: GridConfig;
  participants: GridConfig;
  meetingTitle: GridConfig;
  statusConfirmed: GridConfig;
}

const CalendarEventInListMode = (props: Props) => {
  const intl = useIntl();
  const { reloadBookings, eventDetails: meeting } = props;
  const classes = useStyles();
  let variant = Variant.UPCOMING_BOOKINGS;
  if (
    meeting.status === MeetingStatusType.Completed ||
    meeting.status === MeetingStatusType.Cancelled
  ) {
    variant = Variant.PAST_BOOKINGS;
  }
  const gridConfig: GridConfigList = {
    buttons: {
      md: 12,
      sm: 12,
      xs: 12,
    },
    date: { md: 2, sm: 4, xs: 6 },
    participants: { md: 4, sm: 4, xs: 6 },
    meetingTitle: { md: 12, sm: 12, xs: 12 },
    statusConfirmed: { md: 2, sm: 4, xs: 6 },
  };
  const [authenticatedUser] = useAuthenticatedUser();
  return (
    <div className={classes.tooltipMinWidth}>
      <MeetingStatusChip status={meeting.status} />
      <Grid container>
        {meeting.title && (
          <Grid item {...gridConfig.meetingTitle}>
            <Typography color="textPrimary" variant="h6">
              {meeting.title}
            </Typography>
          </Grid>
        )}
        <Grid item {...gridConfig.date}>
          <BookingsListItemText
            headerText={intl.formatMessage(messages.dateTitle)}
            primaryText={getFormattedDate(meeting.startDateTime)}
            secondaryText={getFormattedTimespan(
              meeting.startDateTime,
              meeting.duration
            )}
          />
        </Grid>
        {variant === Variant.UPCOMING_BOOKINGS && (
          <Grid item {...gridConfig.statusConfirmed}>
            <BookingsListItemText
              headerText={intl.formatMessage(messages.statusConfirmed)}
              primaryText={`${getNumberOfConfirmedParticipants(
                meeting.participants
              )} ${intl.formatMessage(messages.statusOf)} ${
                meeting.participants?.length
              }`}
            />
          </Grid>
        )}
        <Grid item {...gridConfig.participants}>
          <BookingsListItemText
            headerText={intl.formatMessage(messages.participantsTitle)}
          />
          <AvatarGroup max={5} participants={meeting.participants} />
        </Grid>
        <Grid item {...gridConfig.buttons} className={classes.buttonGrid}>
          <UpdateMeetingButton
            contextVariant={variant}
            onChanged={reloadBookings}
            className={classes.button}
            meeting={meeting}
          />
          <CancelMeetingButton
            contextVariant={variant}
            onCanceledMeeting={reloadBookings}
            className={classes.button}
            label={intl.formatMessage(messages.cancelMeetingButton)}
            meeting={meeting}
          />
          <AcceptMeetingButton
            onAccepted={reloadBookings}
            className={classes.button}
            contextVariant={variant}
            meeting={meeting}
          />
          {meeting.status === MeetingStatusType.Created &&
            variant === Variant.UPCOMING_BOOKINGS &&
            authenticatedUser.user?.userRole !== UserRoleType.Staff && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                }}
              >
                {intl.formatMessage(messages.meetingNotStartedYet)}
              </Button>
            )}
          <ConnectToMeetingButton
            className={classes.button}
            contextVariant={variant}
            meeting={meeting}
          />
          <BookingHistoryButton
            className={classes.button}
            contextVariant={variant}
            meeting={meeting}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CalendarEventInListMode;
