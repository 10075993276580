import React, { useEffect, useRef } from "react";
import { Typography, Box, useMediaQuery, useTheme } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useStyles } from "./DisplayChat.style";
import { messages } from "./DisplayChat.messages";
import ChatMessage from "../ChatMessage/ChatMessage";
import { ChatMessageResponse } from "../../../../generated/meetingchat-api";
import { MeetingParticipantResponse } from "../../../../generated/meeting-api";
import { getParticipantByUserId } from "../../../../Utils/Meeting";

interface Props {
  chatMessages: ChatMessageResponse[];
  participants?: MeetingParticipantResponse[];
  counterNewMessage: number;
  fullHeight?: boolean;
}

const DisplayChat = (props: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const messagesEndRef: React.RefObject<HTMLDivElement> | null = useRef<HTMLDivElement>(
    null
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(isSmallScreen)();
  const { fullHeight, chatMessages, participants, counterNewMessage } = props;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [counterNewMessage]);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  if (chatMessages.length === 0)
    if (!fullHeight) {
      return (
        <Typography className={classes.nothingToShowText} variant="body2">
          <Box fontStyle="italic">
            {intl.formatMessage(messages.noMessages)}
          </Box>
        </Typography>
      );
    } else return null;

  return (
    <ul className={fullHeight ? classes.rootFullHeight : classes.root}>
      {chatMessages.map((chatMessage) => (
        <ChatMessage
          participant={getParticipantByUserId(chatMessage.sentBy, participants)}
          chatMessage={chatMessage}
          lastMessageRef={messagesEndRef}
        />
      ))}
    </ul>
  );
};

export default DisplayChat;
