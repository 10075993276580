import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { messages } from "./Bookings.messages";
import BookingsList from "../../Components/BookingsList/BookingsList";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../Providers/CustomerProvider/CustomerProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  AttendanceStatusType,
  MeetingHistoryType,
  MeetingStatusType,
  PaginatedMeetingResponseList,
  SortOrderType,
} from "../../generated/meeting-api";
import CustomPagination from "../../Components/CustomPagination/Pagination";
import { useStyles } from "./Bookings.style";
import Tabs, { TabConfig } from "../../Components/Tabs/Tabs";
import { Variant } from "../../Components/BookingsList/Components/Variant/Variant";
import FeatureController, {
  hasFeatureEnabled,
} from "../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import Calendar from "../../Components/Calendar/CalendarComponent";
import CreateMeetingDialog from "../../Components/Dialogs/CreateMeetingDialog/CreateMeetingDialog";
import PermissionController from "../../Components/PermissionController/PermissionController";
import { UserAuthorityType, UserRoleType } from "../../generated/user-api";
import { meetingService } from "../../Providers/ServiceProvider/ServiceProvider";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { PaginatedMeetingList } from "../../Models/Meeting";
import CreateMeetingButton from "../../Components/Buttons/CreateMeetingButton/CreateMeetingButton";

const Bookings = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const customerFeatures = useCustomerFeatures();
  const [authenticatedUser] = useAuthenticatedUser();
  const [pageSize] = useState<number>(10);
  const [incomingBookingsRqPage, setIncomingBookingsRqPage] = useState<number>(
    1
  );
  const setSpinner = useSpinner()[1];
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [ongoingBookingsRqPage, setOngoingBookingsRqPage] = useState<number>(1);
  const [upcomingBookingsPage, setUpcomingBookingsPage] = useState<number>(1);
  const [pastBookingsPage, setPastBookingsPage] = useState<number>(1);
  const [firstLoadingComplete, setFirstLoadingComplete] = useState<boolean>(
    false
  );
  const [
    upcomingBookings,
    setUpcomingBookings,
  ] = useState<PaginatedMeetingList>();
  const [
    incomingBookingRequests,
    setIncomingBookingRequests,
  ] = useState<PaginatedMeetingList>();
  const [
    ongoingBookingRequests,
    setOngoingBookingRequests,
  ] = useState<PaginatedMeetingList>();
  const [pastBookings, setPastBookings] = useState<PaginatedMeetingList>();

  const [isPastBookingsLoading, setIsPastBookingsLoading] = useState<boolean>(
    false
  );
  const [
    isUpcomingBookingsLoading,
    setIsUpcomingBookingsLoading,
  ] = useState<boolean>(false);
  const [
    isOngoingBookingRequestsLoading,
    setIsOngoingBookingRequestsLoading,
  ] = useState<boolean>(false);
  const [
    isIncomingBookingRequestsLoading,
    setIsIncomingBookingRequestsLoading,
  ] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [defaultDate, setDefaultDate] = useState<Date>(new Date());
  const isLoading = (): boolean => {
    return (
      isPastBookingsLoading ||
      isUpcomingBookingsLoading ||
      isOngoingBookingRequestsLoading ||
      isIncomingBookingRequestsLoading
    );
  };

  const loadUpcomingBookings = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsUpcomingBookingsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: upcomingBookingsPage,
          pageSize,
          customerId,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Future,
          attendanceStatuses: [
            AttendanceStatusType.Confirmed,
            AttendanceStatusType.Declined,
          ],
        })
        .then((res) => setUpcomingBookings(res))
        .then(() => setFirstLoadingComplete(true))
        .finally(() => setIsUpcomingBookingsLoading(false));
    }
  };

  const loadIncomingBookingRequests = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsIncomingBookingRequestsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: incomingBookingsRqPage,
          pageSize,
          customerId,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Future,
          attendanceStatuses: [AttendanceStatusType.NotConfirmed],
          statuses: [MeetingStatusType.InProgress, MeetingStatusType.Created],
        })
        .then((res) => setIncomingBookingRequests(res))
        .finally(() => setIsIncomingBookingRequestsLoading(false));
    }
  };

  const loadOngoingBookingRequests = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsOngoingBookingRequestsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: ongoingBookingsRqPage,
          pageSize,
          customerId,
          createdBy: authenticatedUser.user.id,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Future,
          statuses: [MeetingStatusType.InProgress, MeetingStatusType.Created],
        })
        .then((res) => setOngoingBookingRequests(res))
        .finally(() => setIsOngoingBookingRequestsLoading(false));
    }
  };

  const loadPastBookings = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsPastBookingsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: pastBookingsPage,
          pageSize,
          customerId,
          sortOrder: SortOrderType.Descending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Past,
        })
        .then((res) => setPastBookings(res))
        .finally(() => setIsPastBookingsLoading(false));
    }
  };

  const reloadAllBookings = () => {
    if (
      hasFeatureEnabled(
        CustomerFeatureType.UsesMeetingConfirmation,
        customerFeatures
      )
    ) {
      loadOngoingBookingRequests();
      loadIncomingBookingRequests();
    } else {
      setOngoingBookingRequests({} as PaginatedMeetingResponseList);
      setIncomingBookingRequests({} as PaginatedMeetingResponseList);
    }
    loadPastBookings();
    loadUpcomingBookings();
  };

  useEffect(() => {
    loadUpcomingBookings();
  }, [upcomingBookingsPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadIncomingBookingRequests();
  }, [incomingBookingsRqPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadOngoingBookingRequests();
  }, [ongoingBookingsRqPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadPastBookings();
  }, [pastBookingsPage, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenCreateMeetingDialog = (date: Date) => {
    const offset = new Date().getTimezoneOffset();
    const defaultMeetingDate = new Date(date);
    if (date.toString().length === 10) {
      defaultMeetingDate.setHours(9);
    } else {
      defaultMeetingDate.setMinutes(new Date(date).getMinutes() + offset);
    }
    setDefaultDate(defaultMeetingDate);
    setIsDialogOpen(true);
  };

  const pastMeetingsComponent = () => {
    return (
      <div
        className={
          isLoading() && !firstLoadingComplete
            ? classes.hidden
            : classes.bottomMargin
        }
      >
        <BookingsList
          variant={Variant.PAST_BOOKINGS}
          bookingsList={pastBookings?.data}
          reloadBookings={reloadAllBookings}
        />
        <CustomPagination
          onChange={setPastBookingsPage}
          pagination={pastBookings?.pagination}
        />
      </div>
    );
  };

  const upcomingMeetingsComponent = () => {
    return (
      <div
        className={
          isLoading() && !firstLoadingComplete
            ? classes.hidden
            : classes.bottomMargin
        }
      >
        <BookingsList
          variant={Variant.UPCOMING_BOOKINGS}
          bookingsList={upcomingBookings?.data}
          reloadBookings={reloadAllBookings}
        />
        <CustomPagination
          onChange={setUpcomingBookingsPage}
          pagination={upcomingBookings?.pagination}
        />
      </div>
    );
  };

  const calendarComponent = () => {
    return (
      <div className={isLoading() ? classes.hidden : classes.bottomMargin}>
        <Calendar
          createNewMeeting={handleOpenCreateMeetingDialog}
          refreshData={refreshData}
        />
      </div>
    );
  };

  const handleWhenMeetingCreated = () => {
    setIsDialogOpen(false);
    setRefreshData(!refreshData);
  };

  const tabConfig: TabConfig[] = [
    {
      title: intl.formatMessage(messages.upcomingBookingsTitle),
      component: upcomingMeetingsComponent(),
    },
    {
      title: intl.formatMessage(messages.pastBookingsTitle),
      component: pastMeetingsComponent(),
    },
    {
      title: intl.formatMessage(messages.viewCalendarTitle),
      component: calendarComponent(),
    },
  ];

  return (
    <>
      <div className={classes.actionContainer}>
        <CreateMeetingButton
          onCreated={loadUpcomingBookings}
          color="default"
          variant="text"
          overrideLabel={intl.formatMessage(messages.bookMeetingButton)}
          isAdmin
        />
      </div>
      <PermissionController
        allowedRoles={[UserRoleType.Staff]}
        atLeastOneOfAuthoritiesStaff={[
          UserAuthorityType.BookMeeting,
          UserAuthorityType.BookMeetingOther,
        ]}
      >
        <CreateMeetingDialog
          onMeetingCreated={handleWhenMeetingCreated}
          isDialogOpen={isDialogOpen}
          onDialogClose={() => setIsDialogOpen(false)}
          defaultDate={defaultDate}
          isAdmin={false}
        />
      </PermissionController>
      <FeatureController
        requiredFeature={CustomerFeatureType.UsesMeetingConfirmation}
      >
        {incomingBookingRequests?.data &&
          incomingBookingRequests.data?.length > 0 && (
            <Accordion
              defaultExpanded
              className={classes.accordionIncomingBookings}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="incoming-bookings"
                id="incoming-bookings-header"
              >
                <Typography
                  variant="h6"
                  component="h1"
                  className={classes.heading}
                >
                  {intl.formatMessage(messages.incomingBookingRequestsTitle)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Container disableGutters>
                  <BookingsList
                    variant={Variant.INCOMING_BOOKING_REQUESTS}
                    bookingsList={incomingBookingRequests?.data}
                    reloadBookings={reloadAllBookings}
                  />
                  <CustomPagination
                    onChange={setIncomingBookingsRqPage}
                    pagination={incomingBookingRequests?.pagination}
                  />
                </Container>
              </AccordionDetails>
            </Accordion>
          )}

        {ongoingBookingRequests?.data &&
          ongoingBookingRequests.data?.length > 0 && (
            <Accordion
              defaultExpanded
              className={classes.accordionOngoingBookings}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="ongoing-bookings"
                id="ongoing-bookings-header"
              >
                <Typography
                  component="h2"
                  variant="h6"
                  className={classes.heading}
                >
                  {intl.formatMessage(messages.ongoingBookingsRequestTitle)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <Container disableGutters>
                  <BookingsList
                    variant={Variant.ONGOING_BOOKING_REQUESTS}
                    bookingsList={ongoingBookingRequests?.data}
                    reloadBookings={reloadAllBookings}
                  />
                  <CustomPagination
                    onChange={setOngoingBookingsRqPage}
                    pagination={ongoingBookingRequests?.pagination}
                  />
                </Container>
              </AccordionDetails>
            </Accordion>
          )}
      </FeatureController>
      <Tabs
        defaultTab={0}
        showTabs
        tabConfigs={tabConfig}
        className={isLoading() && !firstLoadingComplete ? classes.hidden : ""}
      />
    </>
  );
};

export default Bookings;
