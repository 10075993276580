import * as Yup from "yup";

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

// text string is used as a key to fetch intl message
const UpdateUserValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .max(50, "firstNameTooLong")
      .required("firstNameRequired"),
    lastName: Yup.string()
      .max(50, "lastNameTooLong")
      .required("lastNameRequired"),
    mobilePhoneNumber: Yup.string().matches(phoneRegExp, "phoneNumberInvalid"),
    email: Yup.string().email("emailInvalid"),
  });
};

export default UpdateUserValidationSchema;
