import React from "react";
import { FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@material-ui/core";
import { IntlShape, useIntl } from "react-intl";
import { messages } from "./MeetingFields.messages";
import { messages as errorMessages } from "./Components/Validation/Validation.messages";
import { UserResponse, UserRoleType } from "../../../generated/user-api";
import AddOrCreateUserButton from "../../Buttons/AddOrCreateUserButton/AddOrCreateUserButton";
import CardHeading from "../../CardHeading/CardHeading";
import TextField from "../TextField/TextField";
import UserSelectField from "../UserSelectField/UserSelectField";
import DateField, { Type } from "../DateField/DateField";
import ParticipantNotAvailableList from "./Components/ParticipantNotAvailableList/ParticipantNotAvailableList";
import { User } from "../../../Models/User";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";

export interface MeetingFormikValues {
  title: string;
  startDateTime: Date;
  duration: number;
  participants: User[];
  externalParticipants: User[];
  staffParticipants: User[];
}

interface Props {
  formik: FormikProps<MeetingFormikValues>;
  disabledFields?: string[];
  clientFixedList?: User[];
  clientOptionList?: User[];
  externalFixedList?: User[];
  externalOptionList?: User[];
  staffFixedList?: User[];
  staffOptionList?: User[];
  meetingLeader?: User;
  meetingToBeUpdated?: string;
}

const getErrorMessage = (intl: IntlShape, error?: any) => {
  if (!error || !errorMessages[error]) return "";
  return intl.formatMessage(errorMessages[error]);
};

const MeetingFields = (props: Props) => {
  const intl = useIntl();
  const {
    formik,
    disabledFields,
    clientFixedList,
    clientOptionList,
    externalOptionList,
    staffOptionList,
    meetingLeader,
    meetingToBeUpdated,
    staffFixedList,
    externalFixedList,
  } = props;
  const { errors } = formik;

  const addCreatedUserAsExternalParticipant = (user: UserResponse) => {
    formik.setFieldValue(
      "externalParticipants",
      [...formik.values.externalParticipants, user],
      true
    );
  };

  const addCreatedUserAsClientParticipant = (user: UserResponse) => {
    formik.setFieldValue(
      "participants",
      [...formik.values.participants, user],
      true
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="title"
          formik={formik}
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.titleLabel)}
          errorMsg={getErrorMessage(intl, errors.title?.toString())}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField
          id="startDateTime"
          label={intl.formatMessage(messages.dateTimeLabel)}
          timeLabel={intl.formatMessage(messages.calendarTimeLabel)}
          formik={formik}
          errorMsg={getErrorMessage(intl, errors.startDateTime)}
          type={Type.DATE_TIME}
          disabledFields={disabledFields}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="duration"
          formik={formik}
          inputMode="number"
          disabledFields={disabledFields}
          label={intl.formatMessage(messages.durationLabel)}
          errorMsg={getErrorMessage(intl, errors.duration?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <CardHeading>
          {intl.formatMessage(messages.participantsHeader).toUpperCase()}
        </CardHeading>
        <UserSelectField
          id="staffParticipants"
          formik={formik}
          disabledFields={disabledFields}
          meetingLeader={meetingLeader}
          useCheckAvailability={!meetingToBeUpdated}
          userOptionList={staffOptionList}
          userFixedList={staffFixedList}
          label={intl.formatMessage(messages.staffParticipantsLabel)}
          errorMsg={getErrorMessage(intl, errors.staffParticipants?.toString())}
        />
      </Grid>
      <Grid item xs={12}>
        <UserSelectField
          id="participants"
          label={intl.formatMessage(messages.participantsLabel)}
          formik={formik}
          userFixedList={clientFixedList}
          userOptionList={clientOptionList}
          disabledFields={disabledFields}
          errorMsg={getErrorMessage(intl, errors.participants?.toString())}
        />
        <AddOrCreateUserButton
          userRole={UserRoleType.Client}
          dialogTitle={intl.formatMessage(messages.createClientDialogTitle)}
          label={intl.formatMessage(messages.createClientButtonLabel)}
          onCreated={addCreatedUserAsClientParticipant}
        />
      </Grid>
      <FeatureController requiredFeature={CustomerFeatureType.ExternalUsers}>
        <Grid item xs={12}>
          <UserSelectField
            id="externalParticipants"
            label={intl.formatMessage(messages.externalParticipantsLabel)}
            formik={formik}
            userOptionList={externalOptionList}
            userFixedList={externalFixedList}
            disabledFields={disabledFields}
            errorMsg={getErrorMessage(
              intl,
              errors.externalParticipants?.toString()
            )}
          />
          <AddOrCreateUserButton
            userRole={UserRoleType.External}
            dialogTitle={intl.formatMessage(messages.createExternalDialogTitle)}
            label={intl.formatMessage(messages.createExternalButtonLabel)}
            onCreated={addCreatedUserAsExternalParticipant}
          />
          <ParticipantNotAvailableList
            formik={formik}
            excludedMeetingId={meetingToBeUpdated}
          />
        </Grid>
      </FeatureController>
    </Grid>
  );
};

export default MeetingFields;
