import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSubscription } from "react-stomp-hooks";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import ProfileCard from "./Components/ProfileCard/ProfileCard";
import { useStyles } from "./Dashboard.style";
import QuickMenuCard from "./Components/QuickMenuCard/QuickMenuCard";
import BookingsCard from "./Components/BookingsCard/BookingsCard";
import MessagesCard from "./Components/MessagesCard/MessagesCard";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import OngoingMeetingCard from "./Components/OngoingMeetingCard/OngoingMeetingCard";
import IncomingBookingsCard from "./Components/IncomingBookingsCard/IncomingBookingsCard";
import FeatureController, {
  hasFeatureEnabled,
} from "../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";
import {
  AttendanceStatusType,
  MeetingHistoryType,
  MeetingStatusType,
  SortOrderType,
} from "../../generated/meeting-api";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../Providers/CustomerProvider/CustomerProvider";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { SortByType } from "../../generated/message-api";
import {
  fileService,
  meetingService,
  messageService,
  notificationService,
} from "../../Providers/ServiceProvider/ServiceProvider";
import { PaginatedMessageThreadList } from "../../Models/Message";
import { PaginatedMeetingList } from "../../Models/Meeting";
import { SocketDestination } from "../../WebSocket/WebSocketSession";
import {
  parseSignalMessage,
  SignalType,
} from "../../WebSocket/model/SignalMessage";
import FileCard from "./Components/FileCard/FileCard";
import { PaginatedFileList } from "../../generated/file-api";
import ModifyUserDialog, {
  Tab,
} from "../../Components/UserForm/ModifyUser/ModifyUserDialog";
import {
  getSessionStorageItem,
  Key,
  removeSessionStorageItem,
} from "../../Utils/SessionStorage";
import PermissionController from "../../Components/PermissionController/PermissionController";
import { UserRoleType } from "../../generated/user-api";
import { messages } from "./Dashboard.messages";

const Dashboard = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const { enqueueSnackbar } = useSnackbar();
  const customerFeatures = useCustomerFeatures();
  const [authenticatedUser] = useAuthenticatedUser();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(true);
  const setSpinner = useSpinner()[1];
  const noneNotifiableUser = getSessionStorageItem(Key.NONE_NOTIFIABLE_USER);
  const [
    paginatedFileList,
    setPaginatedFileList,
  ] = useState<PaginatedFileList>();
  const [
    paginatedMessageThreads,
    setPaginatedMessageThreads,
  ] = useState<PaginatedMessageThreadList>();
  const [
    ongoingBookings,
    setOngoingBookings,
  ] = useState<PaginatedMeetingList>();
  const [
    incomingBookings,
    setIncomingBookings,
  ] = useState<PaginatedMeetingList>();
  const [
    upcomingBookings,
    setUpcomingBookings,
  ] = useState<PaginatedMeetingList>();
  const [
    isOngoingBookingsLoading,
    setIsOngoingBookingsLoading,
  ] = useState<boolean>(false);
  const [
    isIncomingBookingsLoading,
    setIsIncomingBookingsLoading,
  ] = useState<boolean>(false);
  const [
    isUpcomingBookingsLoading,
    setIsUpcomingBookingsLoading,
  ] = useState<boolean>(false);

  const isLoading = (): boolean => {
    return (
      isOngoingBookingsLoading ||
      isIncomingBookingsLoading ||
      isUpcomingBookingsLoading
    );
  };

  const loadOngoingBookings = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsOngoingBookingsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: 1,
          pageSize: 5,
          customerId,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Future,
          statuses: [MeetingStatusType.InProgress],
          attendanceStatuses: [AttendanceStatusType.Confirmed],
        })
        .then((res) => setOngoingBookings(res))
        .finally(() => setIsOngoingBookingsLoading(false));
    }
  };

  const loadIncomingBookingRequests = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsIncomingBookingsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: 1,
          pageSize: 5,
          customerId,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Future,
          attendanceStatuses: [AttendanceStatusType.NotConfirmed],
          statuses: [MeetingStatusType.Created, MeetingStatusType.InProgress],
        })
        .then((res) => setIncomingBookings(res))
        .finally(() => setIsIncomingBookingsLoading(false));
    }
  };

  const loadUpcomingBookings = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsUpcomingBookingsLoading(true);
      meetingService()
        .getMeetingsForUserDecorated(authenticatedUser.user, {
          page: 1,
          pageSize: 5,
          customerId,
          sortOrder: SortOrderType.Ascending,
          userId: authenticatedUser.user.id,
          history: MeetingHistoryType.Future,
          attendanceStatuses: [AttendanceStatusType.Confirmed],
          statuses: [MeetingStatusType.Created],
        })
        .then((res) => setUpcomingBookings(res))
        .finally(() => setIsUpcomingBookingsLoading(false));
    }
  };

  const loadUnreadMessages = () => {
    if (customerId && authenticatedUser.user) {
      messageService()
        .listMessageThreadsDecorated(authenticatedUser.user, {
          customerId,
          onlyUnreadMessages: true,
          page: 1,
          pageSize: 5,
          sortBy: SortByType.SentDate,
          sortOrder: SortOrderType.Descending,
        })
        .then((res) => setPaginatedMessageThreads(res));
    }
  };

  const loadUnOpenedFiles = () => {
    if (customerId && authenticatedUser.user) {
      fileService()
        .listFiles({
          customerId,
          onlyUnopenedFiles: true,
          page: 1,
          pageSize: 5,
          sortOrder: SortOrderType.Descending,
        })
        .then((res) => setPaginatedFileList(res));
    }
  };

  const reloadAllBookings = (): void => {
    if (
      hasFeatureEnabled(
        CustomerFeatureType.UsesMeetingConfirmation,
        customerFeatures
      )
    ) {
      loadIncomingBookingRequests();
    }
    loadUpcomingBookings();
    loadOngoingBookings();
  };

  const triggerNotifications = (userId: string): void => {
    if (noneNotifiableUser) {
      notificationService()
        .triggerBookingNotifications({
          userId,
        })
        .then((res) => {
          enqueueSnackbar(
            intl.formatMessage(messages.success, {
              numberOfBookings: res.bookings.length,
            }),
            {
              variant: "success",
            }
          );
        })
        .then(() => removeSessionStorageItem(Key.NONE_NOTIFIABLE_USER));
    }
  };

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
    removeSessionStorageItem(Key.NONE_NOTIFIABLE_USER);
  };

  useEffect(() => {
    reloadAllBookings();
    loadUnreadMessages();
    loadUnOpenedFiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps

  useSubscription(SocketDestination.SIGNAL_GLOBAL + customerId, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (signalMessage.type === SignalType.MEETING) {
      reloadAllBookings();
    }
    if (signalMessage.type === SignalType.MESSAGE) {
      loadUnreadMessages();
    }
    if (signalMessage.type === SignalType.FILE) {
      loadUnOpenedFiles();
    }
  });

  // Show nothing when loading
  if (isLoading()) {
    return null;
  }

  return (
    <>
      {authenticatedUser.user && (
        <>
          <PermissionController allowedRoles={[UserRoleType.Staff]}>
            {noneNotifiableUser && (
              <ModifyUserDialog
                isOpen={isDialogOpen}
                onClose={handleCloseDialog}
                userId={noneNotifiableUser}
                onUpdated={(user) => triggerNotifications(user.id)}
                visibleTabs={[Tab.GENERAL]}
                submitLabel={intl.formatMessage(messages.submitButton)}
              />
            )}
          </PermissionController>
          <Grid container className={classes.root} spacing={2} justify="center">
            <Grid item xs={12} md={3}>
              <ProfileCard
                user={authenticatedUser.user}
                onMeetingCreated={loadUpcomingBookings}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OngoingMeetingCard bookings={ongoingBookings} />
              <FeatureController
                requiredFeature={CustomerFeatureType.UsesMeetingConfirmation}
              >
                <IncomingBookingsCard
                  bookings={incomingBookings}
                  onReload={reloadAllBookings}
                />
              </FeatureController>
              <BookingsCard
                bookings={upcomingBookings}
                onReload={reloadAllBookings}
              />
              <MessagesCard messageThreads={paginatedMessageThreads?.data} />
              <FileCard files={paginatedFileList?.data} />
            </Grid>
            <Grid item xs={12} md={3}>
              <QuickMenuCard />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Dashboard;
