import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSubscription } from "react-stomp-hooks";
import { Divider, useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { isMacOs } from "react-device-detect";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import useStyles from "./VideoRoom.style";
import useRoom from "../hooks/useRoom";
import NetworkToast from "../NetworkToast/NetworkToast";
import { getUserInitials } from "../../../Utils/User";
import { messages } from "./videoRoom.messages";
import AlertDialog from "../../../Components/Dialogs/AlertDialog/AlertDialog";
import { useSpinner } from "../../../Components/Spinner/Spinner";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { MeetingSessionInfo } from "../../../generated/meeting-api";
import { SocketDestination } from "../../../WebSocket/WebSocketSession";
import {
  parseSignalMessage,
  SignalAction,
  SignalType,
} from "../../../WebSocket/model/SignalMessage";
import { ErrorType } from "../hooks/Error";
import AllowMediaGuideDialog from "../../../Components/Dialogs/AllowMediaGuideDialog/AllowMediaGuideDialog";
import Tabs from "../Components/Tabs/Tabs";
import PrivateNotesSlider from "../Components/PrivateNotesSlider/PrivateNotesSlider";
import ToolBar from "../ToolBar/ToolBar";
import useScreenSharing from "../hooks/useScreenSharing";
import { Meeting } from "../../../Models/Meeting";
import Timer from "../Components/Timer/Timer";
import MeetingTitle from "../Components/MeetingTitle/MeetingTitle";
import TroubleShootButton from "../Components/TroubleShootButton/TroubleShootButton";
import {
  getLocalStorageItem,
  Key,
  setLocalStorageItem,
} from "../../../Utils/LocalStorage";
import { RoutePath } from "../../../Components/Routes/RoutePath";

const userDefaultSettings = {
  publishAudio: true,
  publishVideo: true,
  audioSource: undefined,
  videoSource: undefined,
};

interface Props {
  meeting: Meeting;
  session: MeetingSessionInfo;
}

export default function VideoRoom(props: Props) {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [authenticatedUser] = useAuthenticatedUser();
  const { meeting, session } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [credentials, setCredentials] = useState<any>(null);
  const [isPIPEnabled, setIsPIPEnabled] = useState<boolean>(false);
  const [PIPVideo, setPIPVideo] = useState<number>(0);
  const [isDialogForceClosed, setIsDialogForceClosed] = useState(false);
  const [isMacOsReactionInfoOpen, setIsMacOsReactionInfoOpen] = useState(true);
  const [isGuideDialogOpen, setIsGuideDialogOpen] = useState(false);
  const [isPrivateNotesExpanded, setIsPrivateNotesExpanded] = useState(false);
  const publisherContainerRef = useRef<HTMLDivElement>(null);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const setSpinner = useSpinner()[1];
  const {
    createCall,
    room,
    participants,
    connected,
    networkStatus,
    cameraPublishing,
    errors,
  } = useRoom(session.euProxyUrl || "");
  const {
    isScreenSharing,
    startScreenSharing,
    stopScreenSharing,
  } = useScreenSharing(room);

  const handleCloseMacOsReactionInfo = () => {
    setLocalStorageItem(Key.MAC_OS_REACTIONS_INFO_CLOSED, "true").then(() =>
      setIsMacOsReactionInfoOpen(false)
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePIPAction = () => {
    try {
      const videos = document.getElementsByTagName("video");
      const doc: any = document;
      if (videos) {
        if (videos.length > 0) {
          const video: any = videos[PIPVideo];
          if (isPIPEnabled && doc.pictureInPictureEnabled) {
            doc.exitPictureInPicture().then(() => {
              if (PIPVideo < videos.length - 1) {
                const subVideo: any = videos[PIPVideo + 1];
                subVideo.requestPictureInPicture();
                setPIPVideo(PIPVideo + 1);
              } else {
                setPIPVideo(0);
                setIsPIPEnabled(false);
              }
            });
          } else {
            video.requestPictureInPicture();
            if (videos.length - 1 < PIPVideo) {
              setPIPVideo(0);
            }
            setIsPIPEnabled(true);
          }
        }
      }
    } catch (err) { } // eslint-disable-line
  };

  const getErrorMessage = (hardwareErrors: ErrorType[]) => {
    if (hardwareErrors.includes(ErrorType.OT_USER_MEDIA_ACCESS_DENIED)) {
      return {
        title: intl.formatMessage(
          messages.alertDialogTitleUserMediaAccessDenied
        ),
        content: intl.formatMessage(
          messages.alertDialogContentUserMediaAccessDenied
        ),
        submitLabel: intl.formatMessage(
          messages.alertDialogSubmitLabelUserMediaAccessDenied
        ),
        cancelLabel: intl.formatMessage(
          messages.alertDialogCancelLabelUserMediaAccessDenied
        ),
        isCloseable: false,
      };
    }
    if (
      hardwareErrors.includes(ErrorType.VIDEO_HARDWARE_NOT_FOUND) &&
      hardwareErrors.includes(ErrorType.AUDIO_HARDWARE_NOT_FOUND)
    ) {
      return {
        title: intl.formatMessage(
          messages.alertDialogTitleUserMediaAccessDenied
        ),
        content: intl.formatMessage(
          messages.alertDialogContentUserMediaAccessDenied
        ),
        submitLabel: intl.formatMessage(
          messages.alertDialogSubmitLabelUserMediaAccessDenied
        ),
        cancelLabel: intl.formatMessage(
          messages.alertDialogCancelLabelUserMediaAccessDenied
        ),
        isCloseable: false,
      };
    }
    if (hardwareErrors.includes(ErrorType.VIDEO_HARDWARE_NOT_FOUND)) {
      return {
        title: intl.formatMessage(
          messages.alertDialogTitleVideoHardwareNotFound
        ),
        content: intl.formatMessage(
          messages.alertDialogContentVideoHardwareNotFound
        ),
        submitLabel: intl.formatMessage(
          messages.alertDialogSubmitLabelVideoHardwareNotFound
        ),
        cancelLabel: intl.formatMessage(
          messages.alertDialogCancelLabelVideoHardwareNotFound
        ),
        isCloseable: true,
      };
    }
    if (hardwareErrors.includes(ErrorType.AUDIO_HARDWARE_NOT_FOUND)) {
      return {
        title: intl.formatMessage(
          messages.alertDialogTitleAudioHardwareNotFound
        ),
        content: intl.formatMessage(
          messages.alertDialogContentAudioHardwareNotFound
        ),
        submitLabel: intl.formatMessage(
          messages.alertDialogSubmitLabelAudioHardwareNotFound
        ),
        cancelLabel: intl.formatMessage(
          messages.alertDialogCancelLabelAudioHardwareNotFound
        ),
        isCloseable: true,
      };
    }
    return {
      title: intl.formatMessage(messages.alertDialogTitleHardwareUnavailable),
      content: intl.formatMessage(
        messages.alertDialogContentHardwareUnavailable
      ),
      submitLabel: intl.formatMessage(
        messages.alertDialogSubmitLabelHardwareUnavailable
      ),
      cancelLabel: intl.formatMessage(
        messages.alertDialogCancelLabelHardwareUnavailable
      ),
      isCloseable: false,
    };
  };

  const redirectToBookingDetails = () => {
    history.push({
      pathname: RoutePath.BOOKING_HISTORY,
      search: `?meetingId=${meeting.id}`,
      state: { loadFromMeetingSession: true },
    });
  };

  const forceLeaveMeeting = () => {
    enqueueSnackbar(intl.formatMessage(messages.meetingHasEnded), {
      variant: "success",
    });

    room.leave().then(() => {
      redirectToBookingDetails();
    });
  };

  useEffect(() => {
    setSpinner({
      isLoading: true,
      text: intl.formatMessage(messages.spinnerLabel),
    });
    setCredentials({
      apikey: session.apiKey,
      sessionId: session.sessionId,
      token: session.userToken,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (connected) {
      setSpinner({
        isLoading: false,
        text: undefined,
      });
    }
  }, [connected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (credentials) {
      createCall(
        credentials,
        getUserInitials(
          authenticatedUser.user?.firstName,
          authenticatedUser.user?.lastName
        ),
        {
          ...userDefaultSettings,
        }
      );
    }
  }, [createCall, credentials]); // eslint-disable-line

  useEffect(() => {
    if (participants.length === 0) {
      const element = document.getElementsByClassName(
        "OT_mirrored OT_root OT_publisher OT_fit-mode-cover"
      );
      if (element.length === 1) {
        element[0].setAttribute(
          "style",
          `height: 100%; width: 100%; border-radius: 10px; overflow: hidden`
        );
      }
    }
  }, [connected, participants]); // eslint-disable-line

  useEffect(() => {
    if (connected) {
      const element = document.getElementById(
        "MP_camera_publisher_default_controls"
      );
      if (element !== null) {
        element.remove();
      }
    }
  }, [connected]); // eslint-disable-line

  useSubscription(SocketDestination.SIGNAL_MEETING + meeting.id, (message) => {
    const signalMessage = parseSignalMessage(message.body);
    if (
      signalMessage.type === SignalType.MEETING_SESSION &&
      signalMessage.action === SignalAction.ENDED
    ) {
      forceLeaveMeeting();
    }
  });

  return (
    <>
      {errors.length > 0 && (
        <>
          <AllowMediaGuideDialog
            isOpen={isGuideDialogOpen}
            onClose={() => setIsGuideDialogOpen(false)}
          />
          <AlertDialog
            open={errors.length > 0 && !isDialogForceClosed}
            title={getErrorMessage(errors).title}
            content={getErrorMessage(errors).content}
            submitLabel={getErrorMessage(errors).submitLabel}
            onSubmit={() => window.location.reload()}
            onClose={() =>
              getErrorMessage(errors).isCloseable
                ? setIsDialogForceClosed(true)
                : setIsGuideDialogOpen(true)
            }
            cancelLabel={getErrorMessage(errors).cancelLabel}
          />
        </>
      )}

      <div className={classes.rootContainer}>
        <div className={classes.columnContainer}>
          {isMacOs &&
            isMacOsReactionInfoOpen &&
            getLocalStorageItem(Key.MAC_OS_REACTIONS_INFO_CLOSED) !==
              "true" && (
              <Alert
                className={classes.alert}
                severity="info"
                variant="filled"
                onClose={handleCloseMacOsReactionInfo}
              >
                {intl.formatMessage(messages.alertBannerMacOSReactionsInfo, {
                  link: (
                    <b>
                      <a
                        href={intl.formatMessage(
                          messages.alertBannerMacOSReactionsInfoLink
                        )}
                      >
                        {intl.formatMessage(
                          messages.alertBannerMacOSReactionsInfoLinkName
                        )}
                      </a>
                    </b>
                  ),
                })}
              </Alert>
            )}
          <div className={classes.topBarContainer}>
            {isSmallScreen && (
              <>
                <TroubleShootButton />
                <Divider orientation="vertical" />
              </>
            )}
            <MeetingTitle title={meeting.title} />
            <Divider orientation="vertical" />
            <Timer expectedEndDateTime={meeting.expectedEndDateTime} />
          </div>
          <div className={classes.rowContainer}>
            <div
              id="roomContainer"
              className={classes.roomContainer}
              hidden={participants.length < 1}
            >
              <NetworkToast networkStatus={networkStatus} />
            </div>
            <div
              id="publisherContainer"
              ref={publisherContainerRef}
              hidden={!connected}
              className={
                participants.length === 0
                  ? classes.bigPublisherContainer
                  : classes.publisherContainer
              }
            />
            <ToolBar
              meetingId={meeting.id}
              room={room}
              connected={connected}
              participants={participants}
              cameraPublishing={cameraPublishing}
              isScreenSharing={isScreenSharing}
              startScreenSharing={startScreenSharing}
              stopScreenSharing={stopScreenSharing}
            />
          </div>
          {!isSmallScreen && (
            <PrivateNotesSlider
              meetingId={meeting.id}
              isExpanded={isPrivateNotesExpanded}
              onClose={() => setIsPrivateNotesExpanded(false)}
            />
          )}
        </div>
        <Tabs meeting={meeting} onNotesClick={setIsPrivateNotesExpanded} />
      </div>
    </>
  );
}
